import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from '..'

import { setAllowAnalytics } from '../../actions'
import { localStore } from '../../helpers/storageFactory'

import './styles.scss'

const CookieBanner = ({ setAllowAnalytics }) => {
  const [isDismissed, setDismissed] = useState(false)
  const hasDecided = typeof JSON.parse(localStore.getItem('smb-analytics')) === 'boolean'

  if (isDismissed || hasDecided) return null

  const onDecision = (allowAnalytics) => () => {
    setDismissed(true)
    setAllowAnalytics(allowAnalytics)
  }

  return (
    <div className="cookie-banner">
      <p className="type--b1">
        We use cookies to analyze our traffic. You consent to our cookies if you continue to use our
        website. For more information, visit our{' '}
        <Link href="/privacy-policy.pdf" color="blue-1">
          privacy policy
        </Link>
        .
      </p>
      <button onClick={onDecision(true)} className="type--a35 cookie-banner__accept">
        Accept
      </button>
      <button onClick={onDecision(false)} className="type--a34 cookie-banner__close">
        X
      </button>
    </div>
  )
}

export default connect(null, { setAllowAnalytics })(CookieBanner)
